module.exports = [{
      plugin: require('../node_modules/@qite/gatsby-source-tide/gatsby-browser.js'),
      options: {"plugins":[],"host":"https://tide-voetbalreizen7952-acceptance.tidesoftware.be","apiKey":"7fbbc24a-38b9-4c27-bb89-076a0c36dcbf","isPreview":false,"ignoreSslErrors":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Voetbalreizen.com","short_name":"Voetbalreizen.com","start_url":"/","background_color":"#EF8121","theme_color":"#EF8121","display":"minimal-ui","icon":"src/images/voetbalreizen/voetbalreizen-icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"6609f04cf30a4e4f8f0f6b44496492bd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://voetbalreizen-staging.qite.be","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
